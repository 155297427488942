/**
 * NavBar And Showcase
 * 
 * @Author: Focci
 * @Date: 2023-06-12 14:43:15
 * @Last Modified by: Focci
 * @Last Modified time: 2023-06-12 14:43:15
 */

'use client'

import PropTypes from 'prop-types'
import Advert from '@page/common/ad'

function NavBarAndPromotion({
  data = {},
  navbar = null,
}) {
  return (
    <div className="grid grid-cols-1 md:grid-cols-4 gap-y-3 md:gap-3">
      <div className="bg-white">
        {navbar}
      </div>
      <div className="col-span-2 flex flex-col gap-3">
        <Advert fill subtile adData={data['2071']} width={700} height={400} />
        <div className="grid grid-cols-1 md:grid-cols-3 gap-3">
          <Advert fill subtile adData={data['2072']} width={215} height={135} />
          <Advert fill subtile adData={data['2073']} width={215} height={135} />
          <Advert fill subtile adData={data['2074']} width={215} height={135} />
        </div>
      </div>
      <div>
        <Advert fill subtile adData={data['2075']} width={330} height={537} />
      </div>
    </div>
  )
}

NavBarAndPromotion.propTypes = {
  data: PropTypes.object,
  navbar: PropTypes.node,
}

export default NavBarAndPromotion
