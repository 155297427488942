/* eslint-disable react/no-danger */
import Link from 'next/link'
import { useTranslations } from 'next-intl'
import { Button } from '@comp/material'
import { omit } from 'lodash-es'

export function renderGroup(params) {
  return (
    <li key={params.key} className="w-full mb-2">
      <div className="text-f.7 pl-3 text-meta font-medium">
        {params.group}
      </div>
      <div className="text-f.7 my-1">{params.children}</div>
    </li>
  )
}

export function SearchButton(buttonProps) {
  const ts = useTranslations('search')
  return (
    <Button 
      sx={{
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        fontSize: '.8rem'
      }}
      {...buttonProps}
    >
      {ts('search')}
    </Button>
  )
}

export function renderOption(props, option) {
  if(option.type === 'link') {
    return (
      <div
        {...omit(props, ['key', 'onClick', 'onTouchStart'])} 
        key={option.value} 
        className={props.className}
      >
        <Link href={option.url || '/'} className="w-full flex">
          <span dangerouslySetInnerHTML={{ __html: option.label }} />
        </Link>
      </div>
    )
  }

  return (
    <div 
      {...omit(props, ['key'])} 
      key={option.value}
      dangerouslySetInnerHTML={{ __html: option.label }}
    />
  )
}
