/**
 * 谷歌广告
 * 
 * @Author: Focci
 * @Date: 2023-11-02 16:52:12
 * @Last Modified by: Focci
 * @Last Modified time: 2023-11-02 16:52:12
 */

'use client'

import GoogleAdvert from '@comp/google/advert'
import { get, size, some } from 'lodash-es'
import PropTypes from 'prop-types'
import { useCallback, useMemo, useState } from 'react'
import Advert from './Advert'
import ShowcaseCardVertical from './ad-card/ShowcaseCardVertical'
import ShowcaseCardRectangle from './ad-card/ShowcaseCardRectangle'
import ShowcaseCardLandscape from './ad-card/ShowcaseCardLandscape'

function hasLocationAd(data) {
  return some(data, (item) => size(get(item, 'ads')) > 0)
}

// showcase animation 房源卡片广告
const showCaseAnimationCard = {
  '300_600': ShowcaseCardVertical,
  '300_250': ShowcaseCardRectangle,
  landscape: ShowcaseCardLandscape
}

export default function AdvertGoogle({
  advertProps, // 见 Advert
  ...googleProps
}) {
  const { locationId, width, height } = advertProps
  const AdvertItemCard = useMemo(() => showCaseAnimationCard[`${width}_${height}`] || showCaseAnimationCard.landscape, [width, height])

  // 是否设置了google广告
  const [hasGoogleContent, setHasGoogleContent] = useState(true)
  // 是否设置了location的广告
  const [hasLocationContent, setHasLocationContent] = useState(Boolean(locationId))

  // google广告加载完成后的回调
  const googleCallback = useCallback((evt) => {
    setHasGoogleContent(!evt.isEmpty)
  }, [setHasGoogleContent])

  // localtion广告加载完成后的回调
  const locationCallback = useCallback((d) => {
    setHasLocationContent(hasLocationAd(d))
  }, [setHasLocationContent])

  // 如果没有设置google广告，同时也没有设置location广告，则不显示
  if (!hasGoogleContent && !hasLocationContent) return undefined

  // 如果google广告没有数据，同时又设置了 locationId
  // 则加载locationId的数据
  if (!hasGoogleContent && locationId && hasLocationContent) {
    return (
      <Advert
        {...advertProps}
        AdvertItemCard={AdvertItemCard}
        onLoad={locationCallback}
      />
    )
  }

  return (
    <GoogleAdvert
      adSize={[width, height]}
      {...googleProps}
      onLoad={googleCallback}
    />
  )
}

AdvertGoogle.propTypes = {
  id: PropTypes.string,
  advertProps: PropTypes.object,
}
