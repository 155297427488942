/**
 * CustomCarousel
 * 
 * @Author: Focci
 * @Date: 2023-07-21 13:58:30
 * @Last Modified by: Focci
 * @Last Modified time: 2023-07-21 13:58:30
 */

'use client'

// import PropTypes from 'prop-types'
import Carousel from '@comp/swiper/Carousel'

export default function CustomCarousel({
  ...carouselProps
}) {
  return (
    <div>
      <Carousel
        slidesPerView={3}
        slidesPerGroup={3}
        spaceBetween={15}
        leftNavigationWrapperClassName="left-1 md:-left-10"
        rightNavigationWrapperClassName="right-1 md:-right-10"
        paginationWrapperClassName="hidden md:-bottom-6"
        paginationActiveClassName="bg-secondary"
        NavigationProps={{
          className: 'w-8 h-8 bg-white rounded-full text-secondary shadow-md border border-solid border-secondary'
        }}
        {...carouselProps}
      />
    </div>
  )
}

// FeturedOpencourse.propTypes = {
//   data: PropTypes.array,
// }
