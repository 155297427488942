/**
 * Autocomplete
 * https://mui.com/material-ui/api/autocomplete/
 * 
 * @Author: Focci
 * @Date: 2023-07-27 21:29:17
 * @Last Modified by: Focci
 * @Last Modified time: 2023-07-27 21:29:17
 */

'use client'

import PropTypes from 'prop-types'
import clsx from 'clsx'
import NoData from '@comp/NoData'
import Autocomplete from '@mui/material/Autocomplete'
import CloseIcon from '@mui/icons-material/Close'
import SearchIcon from '@mui/icons-material/Search'
import useApp from '@hook/useApp'
import { isWindow } from '@lib/utils'
import { useCallback, useState } from 'react'
import { pick, map, isArray } from 'lodash-es'
import { ButtonBase } from '@comp/material'
import { renderGroup } from './lib'

export function CloseableTag({ 
  height = '', 
  fontSize = '',
  label = '',
  onDelete = () => {}
}) {
  return (
    <div 
      className={clsx([
        'flex items-center gap-x-1 bg-cf2 pr-1 outline-0 ',
        'rounded-full text-f.7 pl-3 overflow-hidden', 
        height, fontSize, 
      ])}
    >
      <span className="truncate block capitalize line-clamp-1">
        {String(label).replace(/-/g, ' ')}
      </span>
      <span 
        className="
          cursor-pointer flex items-center justify-center 
          bg-cd8 hover:bg-c6c rounded-full p-0.5 text-white
        "
      >
        <CloseIcon 
          onClick={onDelete}
          sx={{ fontSize: '.7rem' }}
        />
      </span>
    </div>
  )
}

function LimitTagsText(n) {
  return (
    <span className="p-1 text-f.6 rounded-sm text-f.7 text-gray-500">
      {`${n}+`}
    </span>
  )
}

export const sizeConfig = {
  small: { 
    height: 'min-h-8', innerHeight: 'h-6', fontSize: 'text-f.7', padding: 'p-1'
  },
  default: { 
    height: 'min-h-9', innerHeight: 'h-6', fontSize: 'text-f.7', padding: 'p-1'
  },
  medium: { 
    height: 'min-h-12', innerHeight: 'h-7', fontSize: 'text-f.7', padding: 'p-2'
  },
  large: { 
    height: 'min-h-16', innerHeight: 'h-7', fontSize: 'text-f.8', padding: 'p-3'
  }
}

function renderTags(Tag, sconf, locations) {
  if(!isWindow) {
    return () => []
  }
  
  return (tags, getTagProps) => tags.map((tag, index) => (
    <Tag
      height={sconf.innerHeight}
      fontSize={sconf.fontSize}
      label={locations?.[`${tag.type}_${tag.value}`]?.zh || tag.label}
      onDelete={getTagProps({ index }).onDelete}
      key={index}
    />
  ))
}

export default function CustomAutocomplete({
  prefix = null,
  suffix = null,
  placeholder = 'Type keywords...',
  Tag = CloseableTag,
  size = 'default',
  sizeOptions = sizeConfig,
  wraperClassName = 'focus-within:border-secondary',
  clearable = true,
  onKeyDown = () => {},
  ...autocompleteProps
}) {
  const { autofocus, ...props } = autocompleteProps
  const sconf = sizeOptions[size]
  const { locations } = useApp()
  // 是否已经focus
  const [focused, setFocused] = useState(false)

  // 禁用的选项
  const handleOptionDisabled = useCallback((option) => {
    const { value } = autocompleteProps
    const target = isArray(value) ? value : []

    return map(target, 'value').indexOf(option.value) > -1
  }, [autocompleteProps])
  
  return (
    <Autocomplete
      className="w-full group overflow-hidden"
      filterOptions={(x) => x}
      renderGroup={renderGroup}
      renderTags={renderTags(Tag, sconf, locations)}
      getLimitTagsText={LimitTagsText}
      renderInput={({ InputProps, inputProps }) => {
        const { startAdornment, endAdornment } = InputProps
        const { onClick } = endAdornment?.props?.children[0]?.props ?? {}

        // autofous
        if(autofocus && !focused && inputProps.ref?.current) {
          inputProps.ref.current.focus()
          setFocused(true)
        }

        return (
          <div 
            className={clsx([
              'flex items-center relative w-full border border-helper rounded-sm bg-white',
              'focus-within:shadow-sm', wraperClassName,
              sconf.height, sconf.padding
            ])}
            {...pick(InputProps, ['onClick', 'ref'])}
          >
            {/* prefix */}
            {prefix ?? (
              <div className="flex items-center text-cd1 mx-1 pointer-events-none">
                <SearchIcon sx={{ fontSize: '1.2rem' }} />
              </div>
            )}
            <div className="flex items-center flex-wrap gap-1 flex-auto overflow-hidden">
              {startAdornment}
              {/* input */}
              <input
                {...inputProps}
                placeholder={placeholder}
                onKeyDown={onKeyDown}
                className={clsx([
                  'bg-white outline-0 m-0 flex-auto w-px outline-none', 
                  sconf.fontSize, 
                  sconf.innerHeight
                ])}
              />
            </div>
            {/* 清除内容 */}
            {clearable && onClick && (
              <ButtonBase
                aria-label="Clear" 
                onClick={onClick}
                className="rounded-full opacity-50 hover:opacity-100"
              >
                <CloseIcon sx={{ fontSize: '.8rem', margin: '.15rem' }} />
              </ButtonBase>
            )}
            {suffix}
          </div>
        )
      }}
      getOptionDisabled={handleOptionDisabled}
      loadingText={<div className="text-center text-f.7">Loading...</div>}
      noOptionsText={<NoData className="text-f.8" />}
      getOptionLabel={(option) => option.label}
      {...props}
    />
  )
}

CustomAutocomplete.propTypes = {
  prefix: PropTypes.node,
  suffix: PropTypes.node,
  placeholder: PropTypes.string,
  Tag: PropTypes.node,
  size: PropTypes.oneOf(['small', 'default', 'medium', 'large']),
  sizeOptions: PropTypes.object,
  wraperClassName: PropTypes.string,
  clearable: PropTypes.bool,
}
