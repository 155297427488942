/**
 * Product spec menu
 * 
 * @Author: Focci
 * @Date: 2023-06-13 14:36:36
 * @Last Modified by: Focci
 * @Last Modified time: 2023-06-13 14:36:36
 */

'use client'

import PropTypes from 'prop-types'
import Scrollbar from '@comp/Scrollbar'
import Tab from '@comp/Tab'
import { useLocale, useTranslations } from 'next-intl'
import { useMemo, useState } from 'react'
import { map } from 'lodash-es'
import * as routePS from '@lib/route/productspec'
import NavbarMenuItem from './NavbarMenuItem'
import NavbarSubMenu from './NavbarSubMenu'

function NavbarMenu({
  data = {}
}) {
  const locale = useLocale()
  const tp = useTranslations('productspec')
  const tabValues = useMemo(() => ({
    industryServices: 'industryServices',
    buildingProducts: 'buildingProducts',
  }), [])

  const tabOptions = useMemo(() => ([
    { label: tp('industryServices'), value: tabValues.industryServices },
    { label: tp('buildingProducts'), value: tabValues.buildingProducts },
  ]), [tp, tabValues])

  const [value, setValue] = useState(tabValues.industryServices)
  const [activeId, setActiveId] = useState(null)
  const [children, setChildren] = useState([])
  const [show, setShow] = useState(false)
  const options = data[value] ?? []

  function handleMouseEnter(opt) {
    setShow(true)
    setActiveId(opt.id)
    
    setChildren(map(opt.children, (child) => ({
      ...child,
      url: value === tabValues.buildingProducts
        ? routePS.search(`tag-${child.name}-${child.id}_page-1`, locale)
        : routePS.company(`${child.slug || child.name}-${child.id}`, locale)
    })))
  }

  function handleClose() {
    setShow(false)
    setActiveId(null)
  }

  return (
    <div 
      className="flex flex-col h-full relative border border-cf0 min-h-[25rem]"
      style={{ boxShadow: '0px 2px 24px 0px rgba(0,0,0,0.08)' }}
    >
      <div className="shrink-0">
        <Tab
          full
          options={tabOptions}
          value={value} 
          onChange={(opt) => setValue(opt.value)}
          wrapperClassName="gap-x-0"
          className="p-3 text-f.9 font-medium"
          activeClassName="bg-secondary text-white"
          borderClassName="border-b"
          activeSign={
            <span 
              className="
                absolute bottom-0 left-1/2 transform -translate-x-1/2 
                w-2 h-2 -mb-1 bg-white rotate-45
              "
            />
          }
        />
      </div>
      <div className="flex-auto">
        <Scrollbar>
          <div className="py-4">
            { options.map((opt) => (
              <NavbarMenuItem 
                key={opt.id} 
                data={opt}
                active={opt.id === activeId}
                onMouseEnter={() => handleMouseEnter(opt)}
              />
            ))}
          </div>
        </Scrollbar>
      </div>
      { show && <NavbarSubMenu
        showIcon={value === tabValues.industryServices} 
        data={children}
        onClose={handleClose}
      />}
    </div>
  )
}

NavbarMenu.propTypes = {
  data: PropTypes.object,
}

export default NavbarMenu
