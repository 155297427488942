/**
 * useAbortController
 * 
 * @Author: Focci
 * @Date: 2023-10-10 14:47:51
 * @Last Modified by: Focci
 * @Last Modified time: 2023-10-10 14:47:51
 */

import { isFunction } from 'lodash-es'
import { useRef } from 'react'

export default function useAbortController() {
  const controller = useRef()

  function cancelRequest() {
    if(isFunction(controller.current?.abort)) {
      controller.current.abort()
    }
  }

  function getSignal(cancel = true) {
    cancel && cancelRequest()
    controller.current = new AbortController()
    return controller.current
  }

  return {
    cancelRequest,
    getSignal
  }
}
