/**
 * Products
 * 
 * @Author: Focci
 * @Date: 2023-06-14 09:04:23
 * @Last Modified by: Focci
 * @Last Modified time: 2023-06-14 09:04:23
 */

import PropTypes from 'prop-types'
import clsx from 'clsx'
import ProductSpecGoodCard from '@page/common/card/ProductSpecGoodCard'
import * as routePS from '@lib/route/productspec'
import { isArray } from 'lodash-es'
import { useLocale } from 'next-intl'

function ProductGrid({
  data = [],
  className = 'md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4'
}) {
  const locale = useLocale()
  
  return (
    <div className={clsx(['mt-4 grid grid-cols-1 gap-4', className])}>
      {isArray(data) && data.map((item) => (
        <ProductSpecGoodCard
          key={item.id}
          url={routePS.product(`${item.slug}-${item.id}`, locale)}
          cover={item.cover} 
          title={item.title} 
          logo={item.company?.logo} 
        />
      ))}
    </div>
  )
}

ProductGrid.propTypes = {
  data: PropTypes.array,
}

export default ProductGrid
