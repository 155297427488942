/**
 * Ad
 * 
 * @Author: Focci
 * @Date: 2023-08-27 22:56:32
 * @Last Modified by: Focci
 * @Last Modified time: 2023-08-27 22:56:32
 */

import Advert from './Advert'
import AdvertGoogle from './AdvertGoogle'

export { AdvertGoogle }
export default Advert
