/**
 * Carousel
 * 
 * @Author: Focci
 * @Date: 2023-06-22 19:50:07
 * @Last Modified by: Focci
 * @Last Modified time: 2023-06-22 19:50:07
 */

'use client'

import PropTypes from 'prop-types'
import clsx from 'clsx'
import Swiper, { Pagination, Navigation } from '@comp/swiper'
import { useCallback, useState } from 'react'

export default function Carousel({
  showPagination = true,
  showNavigation = true,
  leftNavigationWrapperClassName = 'left-2',
  rightNavigationWrapperClassName = 'right-2',
  NavigationProps = null,
  paginationWrapperClassName = 'bottom-2',
  paginationActiveClassName = 'bg-primary',
  ...swiperProps
}) {
  const [swiper, setSwiper] = useState(null)
  const [showCtrl, setShowCtrl] = useState(false)
  
  const handleReady = useCallback((swp) => {
    setSwiper(swp)
  }, [])

  const handleSlidesLengthChange = useCallback((swp) => {
    setShowCtrl(swp.params.slidesPerView === 'auto' || swp.slides.length > swp.params.slidesPerView)
  }, [])

  return (
    <Swiper 
      onReady={handleReady} 
      onSlidesLengthChange={handleSlidesLengthChange}
      {...swiperProps}
    >
      {showPagination && showCtrl && (
        <div 
          className={clsx([
            'absolute text-center left-2/4 -translate-x-1/2 z-1', 
            paginationWrapperClassName
          ])}
        >
          <Pagination
            swiper={swiper}
            activeClassName={paginationActiveClassName}
          />
        </div>
      )}
      {showNavigation && showCtrl && (
        <>
          <div className={clsx(['absolute top-2/4 -translate-y-1/2 z-1', leftNavigationWrapperClassName])}>
            <Navigation 
              swiper={swiper} 
              isPrev
              {...NavigationProps}
            />
          </div>
          <div className={clsx(['absolute top-2/4 -translate-y-1/2 z-1', rightNavigationWrapperClassName])}>
            <Navigation 
              swiper={swiper}
              {...NavigationProps}
            />
          </div>
        </>
      )}
    </Swiper>
  )
}

Carousel.propTypes = {
  showPagination: PropTypes.bool,
  showNavigation: PropTypes.bool,
  leftNavigationWrapperClassName: PropTypes.string,
  rightNavigationWrapperClassName: PropTypes.string,
  NavigationProps: PropTypes.object,
  paginationWrapperClassName: PropTypes.string,
  paginationActiveClassName: PropTypes.string,
}
