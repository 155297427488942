/**
 * NavbarSubMenu
 * 
 * @Author: Focci
 * @Date: 2023-06-13 16:31:03
 * @Last Modified by: Focci
 * @Last Modified time: 2023-06-13 16:31:03
 */

import PropTypes from 'prop-types'
import { ClickAwayListener } from '@mui/base'
import Scrollbar from '@comp/Scrollbar'
import clsx from 'clsx'
import NavbarMenuItem from './NavbarMenuItem'

function NavbarSubMenu({
  data = [],
  showIcon = false,
  className = '',
  onClose = () => {}
}) {
  return (
    <ClickAwayListener onClickAway={onClose}>
      <div 
        className={clsx([
          'w-2/3 md:w-full bg-white absolute left-1/3 md:left-full top-0 bottom-0',
          'shadow-xl z-10 bg-white',
          className
        ])}
      >
        <Scrollbar>
          <div className="py-4">
            { data.map((opt) => (
              <NavbarMenuItem 
                key={opt.id}
                showIcon={showIcon}
                showMoreIcon={false}
                divider={showIcon}
                data={opt}
                title={(
                  <span className="text-f.8 text-left">
                    {opt.name}
                    {!showIcon && <span className="opacity-50">{`(${opt.count})`}</span>}
                  </span>
                )}
              />
            ))}
          </div>
        </Scrollbar>
      </div>
    </ClickAwayListener>
  )
}

NavbarSubMenu.propTypes = {
  data: PropTypes.array,
  showIcon: PropTypes.bool,
  className: PropTypes.string,
  onClose: PropTypes.func,
}

export default NavbarSubMenu
