/**
 * AdvertItems
 * 
 * @Author: Focci
 * @Date: 2023-11-02 16:15:54
 * @Last Modified by: Focci
 * @Last Modified time: 2023-11-02 16:15:54
 */

'use client'

import { isWindow } from '@lib/utils'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { ButtonBase } from '@comp/material'
import clsx from 'clsx'
import { isFunction } from 'lodash-es'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useRef, useState } from 'react'
import AdvertItemImage from './ad-card/AdvertItemImage'

function AdvertisementControl({
  className = '',
  icon,
  ...buttonProps
}) {
  return (
    <div 
      className={clsx([
        'absolute bg-white rounded-full w-7 h-7 items-center justify-center',
        'top-1/2 -translate-y-1/2 hidden group-hover/advert:flex', className
      ])}
    >
      <ButtonBase className="rounded-full" {...buttonProps}>
        {icon}
      </ButtonBase>
    </div>
  )
}

export default function AdvertItems({
  data = [],
  width,
  height,
  fill,
  AdvertItemCard = AdvertItemImage,
  pageType,
  onChange,
  showContrl = true, // 是否显示控制器
  autoPlay = true, // 是否自动播放
  duration = 20 // 自动播放间隔时间(单位: s)
}) {
  const len = data.length
  const [index, setIndex] = useState(0)
  const timer = useRef()

  const stopPlay = useCallback(() => {
    isWindow && timer.current && clearInterval(timer.current)
    timer.current = null
  }, [])

  const startPlay = useCallback(() => {
    if(isWindow && autoPlay) {
      timer.current = setInterval(() => {
        let i = index + 1
        if(i >= len) i = 0
        setIndex(i)
        isFunction(onChange) && onChange({ index })
      }, duration * 1000)
    }
  }, [autoPlay, duration, index, len, onChange])

  // 进入视野开始播放，离开停止播放
  const handleViewChange = useCallback(({ inView }) => {
    stopPlay()
    inView && startPlay()
  }, [startPlay, stopPlay])

  useEffect(() => stopPlay, [stopPlay])

  if(len === 0) {
    return null
  }
  
  return (
    <>
      {data.map((item, i) => i === index && (
        <AdvertItemCard
          key={i}
          data={item}
          width={width}
          height={height}
          fill={fill}
          pageType={pageType}
          onViewChange={handleViewChange}
        />
      ))}
      {showContrl && len > 1 && index > 0 && (
        <AdvertisementControl
          onClick={() => setIndex((prev) => prev - 1)}
          className="left-2"
          icon={<ArrowBackIcon />}
        />
      )}
      {showContrl && len > 1 && index < len - 1 && (
        <AdvertisementControl
          onClick={() => setIndex((prev) => prev + 1)}
          className="right-2"
          icon={<ArrowForwardIcon />}
        />
      )}
    </>
  )
}

AdvertItems.propTypes = {
  data: PropTypes.array,
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.bool,
  AdvertItemCard: PropTypes.func,
}
