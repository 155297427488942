/**
 * 精品推荐
 * 
 * @Author: Focci
 * @Date: 2023-06-12 14:48:18
 * @Last Modified by: Focci
 * @Last Modified time: 2023-06-12 14:48:18
 */

'use client'

import PropTypes from 'prop-types'
import Section from '@comp/Section'
import Advert from '@page/common/ad'
import { useTranslations } from 'next-intl'

export default function Feature({
  data = {}
}) {
  const tp = useTranslations('productspec')
  return (
    <Section title={tp('recommendedPicks')}>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-3">
        <div className="flex flex-col gap-3">
          <Advert fill subtile adData={data['2076']} width={500} height={250} />
          <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
            <Advert fill subtile adData={data['2077']} width={240} height={160} />
            <Advert fill subtile adData={data['2078']} width={240} height={160} />
          </div>
        </div>
        <div className="flex flex-col gap-3">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
            <Advert fill subtile adData={data['2079']} width={240} height={160} />
            <Advert fill subtile adData={data['2080']} width={240} height={160} />
          </div>
          <Advert fill subtile adData={data['2081']} width={500} height={250} />
        </div>
      </div>
    </Section>
  )
}

Feature.propTypes = {
  data: PropTypes.object,
}
