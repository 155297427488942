/**
 * Promote info
 * 
 * @Author: Focci
 * @Date: 2023-11-15 16:56:08
 * @Last Modified by: Focci
 * @Last Modified time: 2023-11-15 16:56:08
 */

import PropTypes from 'prop-types'
import { useTranslations } from 'next-intl'

export default function PromoteInfo({ street }) {
  const ts = useTranslations('search')

  return (
    <div className="mt-2 text-f.6 flex items-center">
      <span className="border px-0.5 whitespace-nowrap text-meta">
        {ts('promote')}
      </span>
      <span className="ml-1 line-clamp-1">{street}</span>
    </div>
  )
}

PromoteInfo.propTypes = {
  street: PropTypes.string,
}
