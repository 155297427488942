/**
 * NavbarMenuItem
 * 
 * @Author: Focci
 * @Date: 2023-06-13 16:01:42
 * @Last Modified by: Focci
 * @Last Modified time: 2023-06-13 16:01:42
 */

import PropTypes from 'prop-types'
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded'
import Image from 'next/image'
import clsx from 'clsx'
import Link from 'next/link'

function NavbarMenuItemBody({
  data = {}, // 内容
  showIcon = false, // 显示图片
  showMoreIcon = true, // 显示三角剪头
  divider = false, // 显示分割符
  active = false, // 是否选中
  title = null, // 标题
  ...props
}) {
  return (
    <div
      className={clsx([
        'w-full flex items-center justify-between py-2 px-4',
        'transition-transform hover:bg-gray-50 hover:text-secondary',
        divider && 'border-b border-b-gray-100',
        active && 'text-secondary bg-gray-50',
      ])}
      {...props}
    >
      <div className="flex items-center">
        { showIcon && (
          <div className="shrink-0 mr-4 shrink-0">
            {
              data?.logo
                ? (
                  <Image
                    src={data?.logo || ''}
                    width={80}
                    height={60}
                    alt={data.name}
                    className="object-contain object-left"
                  />
                )
                : <div className="bg-cf0 h-12 w-16" />
            }
          </div>
        )}
        <div className="flex flex-auto flex-col items-start">
          { title ?? <span className="text-f.8 font-medium">{data.name}</span> }
          { data.brief && <span className="opacity-70 text-left line-clamp-2">{data.brief}</span> }
        </div>
      </div>
      { showMoreIcon && <ArrowForwardIosRoundedIcon 
        sx={{ fontSize: '.8rem', opacity: 0.4 }}
      /> }
    </div>
  )
}

NavbarMenuItemBody.propTypes = {
  data: PropTypes.object,
  showIcon: PropTypes.bool,
  showMoreIcon: PropTypes.bool,
  divider: PropTypes.bool,
  active: PropTypes.bool,
  title: PropTypes.node,
}

export default function NavbarMenuItem({
  data = {},
  ...props
}) {
  if(data.url) {
    return (
      <Link href={data.url}>
        <NavbarMenuItemBody data={data} {...props} />
      </Link>
    )
  }

  return (
    <NavbarMenuItemBody data={data} {...props} />
  )
}

NavbarMenuItem.propTypes = {
  data: PropTypes.object,
  url: PropTypes.string,
}
