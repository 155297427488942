/**
 * NoData
 * 
 * @Author: Focci
 * @Date: 2023-06-20 17:33:52
 * @Last Modified by: Focci
 * @Last Modified time: 2023-06-20 17:33:52
 */

import PropTypes from 'prop-types'
import clsx from 'clsx'
import SVGNodata from '@img/nodata.svg'
import { useTranslations } from 'next-intl'

function NoData({
  className = '',
  icon = false
}) {
  const tc = useTranslations('common')

  return (
    <div className={clsx(['text-center text-meta', className])}>
      {
        icon && (
          <SVGNodata className="inline-block w-pix-80 h-pix-80" />
        )
      }
      <div>{tc('noData')}</div>
    </div>
  )
}

NoData.propTypes = {
  className: PropTypes.string,
}

export default NoData
