export function getNumberOfBullets(swiper) {
  if(!swiper.params) return 0
  const slidesLength = swiper.virtual && swiper.params.virtual.enabled
    ? swiper.virtual.slides.length
    : swiper.slides.length
  
  let numberOfBullets = swiper.params.loop
    ? Math.ceil(slidesLength / swiper.params.slidesPerGroup)
    : swiper.snapGrid.length
  
  if (
    swiper.params.freeMode 
    && swiper.params.freeMode.enabled 
    && numberOfBullets > slidesLength
  ) {
    numberOfBullets = slidesLength
  }

  return numberOfBullets
}

export function effectCallback(swiper, handle) {
  if(swiper) {
    handle(swiper)
    swiper.on('resize', handle)
    swiper.on('slideChange', handle)
    swiper.on('slidesLengthChange', handle)
  }

  return () => {
    if(swiper) {
      swiper.off('resize', handle)
      swiper.off('slideChange', handle)
      swiper.off('slidesLengthChange', handle)
    }
  }
}

export const SWIPER_BREAKPOINTS = {
  2: (spaceBetween = 15) => ({
    0: {
      slidesPerView: 1,
      slidesPerGroup: 1,
      spaceBetween
    },
    768: {
      slidesPerView: 2,
      slidesPerGroup: 2,
      spaceBetween
    }
  }),
  3: (spaceBetween = 15) => ({
    0: {
      slidesPerView: 1,
      slidesPerGroup: 1,
      spaceBetween
    },
    768: {
      slidesPerView: 2,
      slidesPerGroup: 2,
      spaceBetween
    },
    1024: {
      slidesPerView: 3,
      slidesPerGroup: 3,
      spaceBetween
    }
  }),
  4: (spaceBetween = 15) => ({
    0: {
      slidesPerView: 1,
      slidesPerGroup: 1,
      spaceBetween
    },
    768: {
      slidesPerView: 2,
      slidesPerGroup: 2,
      spaceBetween
    },
    1024: {
      slidesPerView: 3,
      slidesPerGroup: 3,
      spaceBetween
    },
    1280: {
      slidesPerView: 4,
      slidesPerGroup: 4,
      spaceBetween
    }
  }),
  5: (spaceBetween = 15) => ({
    0: {
      slidesPerView: 1,
      slidesPerGroup: 1,
      spaceBetween
    },
    768: {
      slidesPerView: 2,
      slidesPerGroup: 2,
      spaceBetween
    },
    1024: {
      slidesPerView: 3,
      slidesPerGroup: 3,
      spaceBetween
    },
    1280: {
      slidesPerView: 5,
      slidesPerGroup: 5,
      spaceBetween
    }
  }),
  6: (spaceBetween = 15) => ({
    0: {
      slidesPerView: 1,
      slidesPerGroup: 1,
      spaceBetween
    },
    768: {
      slidesPerView: 2,
      slidesPerGroup: 2,
      spaceBetween
    },
    1024: {
      slidesPerView: 3,
      slidesPerGroup: 3,
      spaceBetween
    },
    1280: {
      slidesPerView: 6,
      slidesPerGroup: 6,
      spaceBetween
    }
  })
}
