/**
 * Swiper
 * 
 * @Author: Focci
 * @Date: 2023-06-22 14:50:12
 * @Last Modified by: Focci
 * @Last Modified time: 2023-06-22 14:50:12
 */

'use client'

import PropTypes from 'prop-types'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay } from 'swiper/modules'
import 'swiper/css'
import Pagination from './Pagination'
import Navigation from './Navigation'
import { SWIPER_BREAKPOINTS } from './lib'

export default function SwiperBase({
  children,
  data = [],
  SlideRender = null, // slide 渲染组件函数
  slidesPerView = 1,
  spaceBetween = 0,
  className = '', // swiper className
  slideClassName = '', // slide className
  keyProp = '', // slide key
  onReady = () => {},
  ...props
}) {
  // 修复初始化没有设置宽度，页出现的抖动问题
  const initialStyle = spaceBetween > 1 && data?.length > 0
    ? { 
      width: `calc((100% - ${spaceBetween * (slidesPerView - 1)}px) / ${slidesPerView} )`, 
      marginRight: `${spaceBetween}px` 
    }
    : null
  
  const { breakpoints } = props
  const defaultBreakpoints = !breakpoints
    ? SWIPER_BREAKPOINTS[slidesPerView] && SWIPER_BREAKPOINTS[slidesPerView](spaceBetween)
    : breakpoints

  return (
    <div className="relative max-w-full">
      <Swiper 
        className={className} 
        slidesPerView={slidesPerView} 
        spaceBetween={spaceBetween} 
        breakpoints={defaultBreakpoints ?? {}} 
        onSwiper={(swiper) => onReady(swiper)}
        modules={[Autoplay]}
        {...props} 
      >
        {data.map((item, index) => (
          <SwiperSlide 
            key={keyProp ? (item[keyProp] || index) : index}
            className={slideClassName}
            style={initialStyle}
          >
            {(itemParam) => <SlideRender index={index} {...itemParam} data={item} />}
          </SwiperSlide>
        ))}
      </Swiper>
      {children}
    </div>
  )
}

SwiperBase.propTypes = {
  children: PropTypes.node,
  data: PropTypes.array.isRequired,
  SlideRender: PropTypes.func.isRequired,
  slidesPerView: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  spaceBetween: PropTypes.number,
  className: PropTypes.string,
  slideClassName: PropTypes.string,
  keyProp: PropTypes.string,
  onReady: PropTypes.func,
}

export {
  Pagination,
  Navigation
}
