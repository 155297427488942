/**
 * ProductSpecFeturedCaseCard
 * 
 * @Author: Focci
 * @Date: 2023-06-21 16:11:10
 * @Last Modified by: Focci
 * @Last Modified time: 2023-06-21 16:11:10
 */

import PropTypes from 'prop-types'
import AspectRatioCover from '@comp/AspectRatioCover'
import Link from 'next/link'
import * as routePS from '@lib/route/productspec'
import Image from 'next/image'
import { useLocale } from 'next-intl'

function ProductSpecFeturedCaseCard({
  data = null,
}) {
  const locale = useLocale()
  const url = routePS.caseDetail(`${data?.slug || data?.title}-${data?.id}`, locale)

  return (
    <Link href={url} className="group block relative w-full">
      <AspectRatioCover
        subtile
        hoverZoomIn
        src={data?.cover}
        width={440}
        height={590}
      />
      <div className="absolute inset-0 top-auto bg-black/50 p-4 text-white">
        <div className="bg-white p-1 inline-block -mt-12">
          <Image 
            src={data?.companyLogo || ''}
            width={160}
            height={60}
            alt={data?.companyName}
            className="object-contain w-32 h-12"
          />
        </div>
        <div className="text-f.9 font-medium leading-4 my-2 line-clamp-2">
          {data?.title}
        </div>
        <div className="opacity-50">{data?.companyName}</div>
      </div>
    </Link>
  )
}

ProductSpecFeturedCaseCard.propTypes = {
  data: PropTypes.object,
}

export default ProductSpecFeturedCaseCard
