/**
 * ProductLists
 * 
 * @Author: Focci
 * @Date: 2023-06-21 17:18:28
 * @Last Modified by: Focci
 * @Last Modified time: 2023-06-21 17:18:28
 */

'use client'

import { ButtonBase } from '@comp/material'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useLocale } from 'next-intl'
import Section, { MoreLink } from '@comp/Section'
import * as routePS from '@lib/route/productspec'
import Scrollbar from '@comp/Scrollbar'
import ProductGrid from '../../ProductGrid'

function ProductLists({
  data = []
}) {
  const locale = useLocale()
  const [index, setIndex] = useState(0)

  return (
    <Section
      TitleTag="div"
      titleClassName="flex gap-4"
      action={<MoreLink 
        className="hover:border-secondary hover:text-secondary"
        link={routePS.search(`tag-${data[index].name}-${data[index].id}_page-1`, locale)} 
      />}
      title={
        <Scrollbar autoHeight>
          <div className="flex gap-4">
            {data.map((item, idx) => (
              <ButtonBase key={item.id} onClick={() => setIndex(idx)}>
                <span 
                  className={clsx([
                    'text-base md:text-f1.4 font-medium whitespace-nowrap',
                    idx === index && 'text-secondary'
                  ])}
                >
                  {item.name}
                </span>
              </ButtonBase>
            ))}
          </div>
        </Scrollbar>
      }
    >
      {data.map((item, idx) => (
        <div 
          key={item.id}
          className={clsx([idx !== index && 'hidden'])}
        >
          <ProductGrid data={item.products} />
        </div>
      ))}
    </Section>
  )
}

ProductLists.propTypes = {
  data: PropTypes.array,
}

export default ProductLists
