/**
 * Pagination
 * 
 * @Author: Focci
 * @Date: 2023-06-04 16:46:32
 * @Last Modified by: Focci
 * @Last Modified time: 2023-06-04 16:46:32
 */

'use client'

import clsx from 'clsx'
import { times } from 'lodash-es'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useState } from 'react'
import { getNumberOfBullets, effectCallback } from './lib'

function Pagination({
  swiper = null,
  activeClassName = '',
  className = 'bg-cd1',
  showIndex = false,
  clickable = true
}) {
  const [activeIndex, setActiveIndex] = useState(0)
  const [slides, setSlides] = useState([])

  const handleInitial = useCallback((swp) => {
    const n = getNumberOfBullets(swp)
    setSlides(times(n, Array))
    setActiveIndex(swp.snapIndex)
  }, [])

  function handleClick(index) {
    swiper.slideTo(index * swiper.params.slidesPerGroup)
  }

  useEffect(
    () => effectCallback(swiper, handleInitial), 
    [handleInitial, swiper]
  )

  return (
    <div className="flex items-center gap-2">
      {slides.map((_slide, index) => (
        <button
          type="button"
          key={index}
          onClick={() => handleClick(index)}
          aria-label={`page ${index + 1}`}
          className={clsx([
            'rounded-full flex items-center justify-center shadow-md',
            !clickable && 'cursor-default',
            activeIndex === index
              ? ['w-2.5 h-2.5 cursor-default bg-primary', activeClassName].join(' ')
              : [className, clickable && 'cursor-pointer', 'w-2 h-2'].join(' ')
          ])}
        >
          {showIndex && (index + 1)}
        </button>
      ))}
    </div>
  )
}

Pagination.propTypes = {
  swiper: PropTypes.object.isRequired,
  activeClassName: PropTypes.string,
  className: PropTypes.string,
  showIndex: PropTypes.bool,
  clickable: PropTypes.bool,
}

export default Pagination
