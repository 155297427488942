/**
 * Tab
 * 
 * @Author: Focci
 * @Date: 2023-06-13 14:39:15
 * @Last Modified by: Focci
 * @Last Modified time: 2023-06-13 14:39:15
 */

'use client'

import PropTypes from 'prop-types'
import { ButtonBase } from '@comp/material'
import clsx from 'clsx'
import { isFunction } from 'swr/_internal'

export default function Tab({
  options = [],
  value = null,
  full = false,
  wrapperClassName = 'gap-x-4', // 容器样式
  borderClassName = '', // 是否有下横线
  buttonClassName = '', // 按钮样式名称
  className = '', // 默认样式名称
  activeClassName = 'text-primary', // 选中的样式名称
  unActiveClassName = '', // 未选中样式名称
  activeSign = null, // 选中标记，比如向上的三角箭头
  onChange = () => {},
  itemRender, // Tab item render
}) {
  return (
    <div className="flex relative">
      <div className={clsx(['flex relative min-w-full z-1', wrapperClassName])}>
        { options.map((opt) => (
          <ButtonBase
            // aria-label={opt.label}
            key={opt.value}
            style={{ width: full ? `${100 / options.length}%` : 'auto' }} 
            onClick={() => onChange(opt)}
            className={buttonClassName}
          >
            <div
              className={clsx([
                'w-full relative truncate transition-colors duration-500 ',
                'whitespace-nowrap',
                className,
                value === opt.value ? activeClassName : unActiveClassName,
              ])}
            >
              {
                isFunction(itemRender) 
                  ? itemRender({ item: opt, actived: value === opt.value })
                  : (
                    <>
                      <span>{opt.label}</span>
                      {value === opt.value && (activeSign || (
                        <span 
                          className="
                            absolute bottom-0 left-1/2 transform -translate-x-1/2 
                            w-2 h-2 -mb-1 bg-white border-t border-l rotate-45
                          "
                        />
                      ))}
                    </>
                  )
              }
            </div>
          </ButtonBase>
        ))}
      </div>
      <span 
        className={clsx([
          'absolute left-0 right-0 bottom-0', 
          borderClassName
        ])}
      />
    </div>
  )
}

Tab.propTypes = {
  options: PropTypes.array,
  value: PropTypes.any,
  full: PropTypes.bool,
  borderClassName: PropTypes.string,
  className: PropTypes.string,
  activeClassName: PropTypes.string,
  buttonClassName: PropTypes.string,
  unActiveClassName: PropTypes.string,
  activeSign: PropTypes.node,
  onChange: PropTypes.func,
  itemRender: PropTypes.func,
}
