'use client'

import PropTypes from 'prop-types'
import useApp from '@hook/useApp'
import clsx from 'clsx'
import Script from 'next/script'
import { Skeleton } from '@comp/material'
import { useEffect, useMemo, useState } from 'react'
import { assign, forEach, isArray, isFunction, isObject, keys, map, max } from 'lodash-es'
import { useTranslations } from 'next-intl'

export function GoogleAdvertConfig({
  dfpPath,
  dfpTags,
  slot,
}) {
  useEffect(() => {
    window.dfp_site = 'Hougarden'
    if (dfpPath) window.dfp_path = dfpPath
    if (dfpTags) window.dfp_tags = assign(dfpTags, { oid: dfpTags.oid ? `${dfpTags.oid}` : 'null' })
  }, [dfpPath, dfpTags])

  useEffect(() => {
    if (!slot) return

    const { googletag = { cmd: [] } } = window
    googletag.cmd.push(() => {
      googletag.display(slot)
    })
  }, [slot])

  return (
    <Script
      src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"
      strategy="lazyOnload"
    />
  )
}

export default function GoogleAdvert({
  adSize,
  adUnit = 'rectangle',
  adPos,
  dfpPath,
  dfpTags,
  id,
  className,
  style,
  events, // { slotRenderEnded: [fn, fn ]}
  desktopVisible = true,
  mobileVisible = true,
  onLoad
}) {
  const ts = useTranslations('search')
  const { isMobile } = useApp()
  const [hasContent, setHasContent] = useState(true)
  const visible = useMemo(() => {
    if (!hasContent) return false
    if (isMobile) return mobileVisible
    return desktopVisible
  }, [isMobile, desktopVisible, mobileVisible, hasContent])

  const [slot, setSlot] = useState()
  useEffect(() => {
    if (typeof window === 'undefined') return undefined
    window.googletag = window.googletag || { cmd: [] }
    const { googletag } = window
    // eslint-disable-next-line no-shadow
    let slot = null
    googletag.cmd.push(() => {
      try {
        slot = googletag
          .defineSlot(dfpPath, adSize || [], id)
          .addService(googletag.pubads())

        googletag
          .pubads()
          .enableSingleRequest()
        googletag.enableServices()

        googletag.pubads().addEventListener('slotRenderEnded', (evt) => {
          if (id === evt.slot.getSlotId().getDomId()) {
            setHasContent(!evt.isEmpty)
            isFunction(onLoad) && onLoad(evt)
          }
        })

        // 添加事件监听
        if (isObject(events)) {
          map(keys(events), (eventName) => {
            googletag.pubads().addEventListener(eventName, (evt) => {
              map(
                isArray(events[eventName]) ? events[eventName] : [],
                (fn) => isFunction(fn) && fn(evt)
              )
            })
          })
        }

        setSlot(slot)
      } catch (error) { /* empty */ }
    })

    return () => {
      googletag && isFunction(googletag.destroySlots) && googletag.destroySlots([slot])
    }
  }, [id, dfpPath, adSize, setSlot, events, onLoad, setHasContent])

  // eslint-disable-next-line no-use-before-define
  const [adstyle, wrapstyle] = getAdStyle(adSize)
  return visible && (
    <>
      <div className={clsx('mx-auto max-w-full overflow-hidden', className)} style={assign(wrapstyle, style)}>
        <div
          id={id}
          data-adsize={isArray(adSize) ? JSON.stringify(adSize) : adSize}
          data-adunit={adUnit}
          data-adpos={adPos}
          style={adstyle}
        >
          <Skeleton sx={assign(adstyle, { transform: 'none' })} />
        </div>
        <span className="inline-block border px-0.5 text-meta mt-1 text-f.6">
          {ts('promote')}
        </span>
      </div>
      <GoogleAdvertConfig
        dfpPath={dfpPath}
        dfpTags={dfpTags}
        slot={slot}
      />
    </>
  )
}

GoogleAdvert.propTypes = {
  id: PropTypes.any.isRequired,
  adSize: PropTypes.any.isRequired,
  adUnit: PropTypes.oneOf(['rectangle', 'leaderboard']),
  adPos: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  dfpPath: PropTypes.string,
  dfpTags: PropTypes.shape({
    oid: PropTypes.string,
    pt: PropTypes.string,
    kw: PropTypes.array
  }),
  className: PropTypes.string,
  style: PropTypes.object,
  events: PropTypes.object,
  onLoad: PropTypes.func,
}

function isArray2d(arr) {
  let res = true
  forEach(arr, (item) => {
    if (!isArray(item)) res = false
  })
  return res
}

function getAdStyle(adsize) {
  if (!adsize) return []
  const adSize = isArray(adsize) ? adsize : JSON.parse(adsize)
  const is2d = isArray2d(adSize)
  const width = []
  const height = []
  if (is2d) {
    forEach(adSize, (item) => {
      width.push(item[0])
      height.push(item[1])
    })
  } else {
    width.push(adSize[0])
    height.push(adSize[1])
  }

  return [
    { minWidth: max(width), minHeight: max(height) },
    { width: max(width) }
  ]
}
