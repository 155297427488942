/**
 * AdvertItemImage
 * 
 * @Author: Focci
 * @Date: 2023-11-02 16:22:22
 * @Last Modified by: Focci
 * @Last Modified time: 2023-11-02 16:22:22
 */

import PropTypes from 'prop-types'
import clsx from 'clsx'
import Image from 'next/image'
import useApp from '@hook/useApp'
import useTracking from '@hook/useTracking'
import { useLocale } from 'next-intl'
import { Waypoint } from 'react-waypoint'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { parseAd, handlePushTrack } from '../lib'

export default function AdvertItemImage({
  data = {},
  width = 300,
  height = 300,
  fill,
  onViewChange = () => {},
}) {
  const locale = useLocale()
  const { pushTrack } = useTracking()
  const [url, setUrl] = useState()
  const [ready, setReady] = useState(false)
  const { isMobile } = useApp()
  const { href, src } = useMemo(() => parseAd(data, locale, isMobile), [data, locale, isMobile])
  
  const Container = href ? 'a' : 'div'

  const handleEnter = useCallback(() => {
    setUrl(src)
    onViewChange({ inView: true })
  }, [src, onViewChange])

  const handleLeave = useCallback(() => onViewChange({ inView: false }), [onViewChange])
  const handleClick = useCallback(
    () => handlePushTrack(data, pushTrack, 'click_ad'), 
    [data, pushTrack]
  )

  useEffect(() => {
    data && handlePushTrack(data, pushTrack, 'show_ad')
  }, [data, pushTrack])

  return (
    <Waypoint onEnter={handleEnter} onLeave={handleLeave}>
      <Container
        {...(href && { href, target: '_blank', 'aria-label': 'Link' })}
        className="absolute inset-0 overflow-hidden w-full h-full"
        onClick={handleClick}
      >
        {url && (
          <Image
            priority
            src={url}
            alt="Advertisement"
            width={width}
            height={height}
            onLoad={() => setReady(true)}
            className={clsx([
              'w-full h-full opacity-0', 
              fill && 'object-cover',
              ready && 'animate-fade-in',
            ])}
          />
        )}
      </Container>
    </Waypoint>
  )
}

AdvertItemImage.propTypes = {
  data: PropTypes.object,
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.bool,
}
