/**
 * Scroll Bar
 * 
 * @Author: Focci
 * @Date: 2023-04-17 09:15:27
 * @Last Modified by: Focci
 * @Last Modified time: 2023-04-17 09:15:27
 */

'use client'
 
// import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import { Scrollbars } from 'react-custom-scrollbars-2'

// https://github.com/RobPethick/react-custom-scrollbars-2/blob/master/docs/API.md
function Scrollbar({ children, ...props }, ref) {
  return (
    <Scrollbars
      autoHide
      ref={ref}
      autoHideTimeout={500}
      autoHideDuration={200}
      hideTracksWhenNotNeeded
      universal={true}
      {...props}
    >
      {children}
    </Scrollbars>
  )
}

// Scrollbar.propTypes = {
//   children: PropTypes.node,
// }

export default forwardRef(Scrollbar)
