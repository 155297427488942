/**
 * Fetured Opencourse
 * 
 * @Author: Focci
 * @Date: 2023-06-21 15:12:30
 * @Last Modified by: Focci
 * @Last Modified time: 2023-06-21 15:12:30
 */

'use client'

import PropTypes from 'prop-types'
import ProductSpecFeturedCaseCard from '@page/common/card/ProductSpecFeturedCaseCard'
import CustomCarousel from '../CustomCarousel'

export default function FeaturedCases({
  data = []
}) {
  return (
    <CustomCarousel
      data={data}
      SlideRender={ProductSpecFeturedCaseCard}
    />
  )
}

FeaturedCases.propTypes = {
  data: PropTypes.array,
}
